export const Maintenance = () => (
   <div className="App">
      <div className="flex items-center justify-center h-screen">
         <div className="text-center text-xl">
            <h1 className="text-3xl">We&rsquo;ll be back soon!</h1>
            <p className="m-10">
               Sorry for the inconvenience but we&rsquo;re performing some
               maintenance at the moment. We&rsquo;ll be back online shortly!
            </p>
            <p>&mdash; The Team</p>
         </div>
      </div>
   </div>
);

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { appInsightsInit } from './ApplicationInsights';
import { Maintenance } from './components/Maintenance';

const browserHistory = createBrowserHistory({ basename: '' });
export const appInsights = appInsightsInit(browserHistory);

// import './i18n';

const isMaintenanceTest =
   browserHistory?.location?.search.includes('maintenance-test');
const showMaintenance =
   process.env.REACT_APP_MAINTENANCE_MODE === 'true' && !isMaintenanceTest;

createRoot(document.getElementById('root')!).render(
   <React.StrictMode>
      {showMaintenance ? <Maintenance /> : <App history={browserHistory} />}
   </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (appInsights) {
   reportWebVitals(({ name, value }) =>
      appInsights.trackMetric({ name, average: value }),
   );
}

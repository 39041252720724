import { Link, useHistory } from 'react-router-dom';
import { DocTypeGet } from 'types';
import DocumentTypeBadge from './DocumentTypeBadge';

interface Props {
   isNew: boolean;
   fileName: string;
   publishedDate: string;
   fundName: string;
   docType: DocTypeGet;
   downloadDocument: () => Promise<void>;
   accountName?: string;
   checked: boolean;
   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
   adminPage?: boolean;
   handleEdit?: (e: any, id: number) => void;
   documentId: number;
   isPublished: boolean;
   emailAttachment: boolean;
   emailNotification: boolean;
   onUnpublishDocument: (e: any, id: number) => void;
   bulkId?: number | null;
   fundPage?: boolean;
   onSendDocumentToUser?: (documentId: number) => void;
   docDate: string;
   isDepublished: boolean;
   isFuturePublished: boolean;
   noPublishDate: boolean;
   emailNotificationSent: boolean;
}

function DocumentRow({
   isNew,
   fileName,
   publishedDate,
   docDate,
   fundName,
   docType,
   downloadDocument,
   accountName,
   checked,
   onChange,
   adminPage,
   handleEdit,
   documentId,
   isPublished,
   emailAttachment,
   emailNotification,
   emailNotificationSent,
   onUnpublishDocument,
   bulkId,
   fundPage,
   isDepublished,
   isFuturePublished,
   noPublishDate,
   onSendDocumentToUser,
}: Props) {
   const history = useHistory();

   const documentViewHref = adminPage
      ? `/admin/document/${documentId}`
      : `/document/${documentId}`;
   const documentViewerData = {
      filename: fileName,
      date: docDate,
      documentType: docType,
   };

   const rowClick: React.MouseEventHandler<HTMLTableRowElement> = e => {
      if (
         !(
            e.target instanceof HTMLInputElement ||
            e.target instanceof HTMLButtonElement ||
            e.target instanceof HTMLAnchorElement
         )
      ) {
         const extension = documentViewerData.filename.split('.').pop();
         if (extension === 'pdf') {
            history.push(documentViewHref, documentViewerData);
         } else {
            downloadDocument();
         }
      }
   };

   const canDepublish = Boolean(
      !isDepublished && (isPublished || isFuturePublished),
   );

   return (
      <tr
         className={
            'bg-white hover:bg-gray-50 w-full px-6 py-4' +
            (!adminPage ? ' cursor-pointer group' : '')
         }
         onClick={rowClick}
      >
         <td className="pl-6">
            <div className="flex items-center h-5">
               <input
                  onChange={onChange}
                  checked={checked}
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 text-blue-600 border-gray-300 rounded"
               />
            </div>
         </td>
         <td className="px-2 py-4 whitespace-normal text-sm text-gray-900">
            <div className="text-left mb-1">
               <DocumentTypeBadge docType={docType} />
            </div>
            <div className="flex">
               {adminPage && (
                  <div className="whitespace-nowrap cursor-default">
                     {isPublished && <span title="Published">&#x2705;</span>}
                     {isFuturePublished && (
                        <span title="Scheduled to be published">&#x231B;</span>
                     )}
                     {isDepublished && (
                        <span title="Depublished">&#10060;</span>
                     )}
                     {noPublishDate && (
                        <span title="No publish date">&#128683;</span>
                     )}
                     {emailNotification && (
                        <>
                           {emailNotificationSent ? (
                              <span title="Email sent">&#x2709;</span>
                           ) : (
                              <span
                                 title="Email scheduled to be sent"
                                 className="opacity-30"
                              >
                                 &#x2709;
                              </span>
                           )}
                           {emailAttachment && (
                              <span title="Email Attachment">&#x1F4CE;</span>
                           )}
                        </>
                     )}
                  </div>
               )}

               <Link
                  title={fileName}
                  to={{ pathname: documentViewHref, state: documentViewerData }}
                  className={
                     'link whitespace-pre-wrap text-left group-hover:underline inline ' +
                     (adminPage ? 'hover:underline' : 'group-hover:underline')
                  }
               >
                  {isNew && !adminPage ? (
                     <span className="px-2 py-0.5 mr-2 rounded text-xs font-medium bg-blue-100 text-blue-800 uppercase tracking-wide inline">
                        New
                     </span>
                  ) : null}

                  {fileName}
               </Link>
            </div>
         </td>

         <td className="px-6 py-4 text-left whitespace-nowrap text-base text-gray-500">
            {docDate}
         </td>

         <td className="px-6 py-4 text-left whitespace-nowrap text-base text-gray-500">
            {isDepublished
               ? 'Depublished'
               : (isPublished || isFuturePublished) && publishedDate}
            {noPublishDate && 'No publish date'}
         </td>

         <td className="px-6 py-4 text-left text-gray-500">
            <div>{fundName}</div>
            {accountName ? (
               <div className="text-xs text-left">{accountName}</div>
            ) : null}
         </td>

         {fundPage && (
            <td className="px-6 py-4 text-left text-base text-gray-500">
               {bulkId}
            </td>
         )}

         <td className="pr-6 py-4 text-left text-sm text-gray-500">
            <div className="mt-3  sm:mt-0 sm:ml-4">
               {adminPage && handleEdit ? (
                  <button
                     onClick={e => handleEdit(e, documentId)}
                     type="button"
                     className="action-btn"
                  >
                     Edit
                  </button>
               ) : (
                  <button
                     onClick={downloadDocument}
                     type="button"
                     className="action-btn"
                  >
                     Download
                  </button>
               )}

               {adminPage && canDepublish && (
                  <button
                     onClick={e => onUnpublishDocument(e, documentId)}
                     type="button"
                     className=" action-btn "
                  >
                     Depublish
                  </button>
               )}
               {adminPage && onSendDocumentToUser && (
                  <button
                     className="action-btn mt-2"
                     onClick={() => onSendDocumentToUser(documentId)}
                  >
                     Resend to user
                  </button>
               )}
            </div>
         </td>
      </tr>
   );
}

export default DocumentRow;

import { DocTypeGet, DocumentGet } from 'types';

import DocumentCard from './DocumentCard';

import { useState, Dispatch, SetStateAction } from 'react';
import { api } from '../lib/api';
import { downloadBlob } from '../lib/file';
import FormModal from './FormModal';
import BulkPublishDocument, {
   BulkForm,
} from '../pages/admin/document/BulkPublishDocument';
import DocumentSortIcon from './DocumentSortIcon';
import Banner from './Banner';

interface DocumentInterface extends Omit<DocumentGet, 'isPublished'> {}

interface Props {
   documents: DocumentInterface[];
   setSelectedDocument?: ({
      url,
      filename,
      date,
      documentType,
   }: DocumentViewerProps) => void;
   handleEdit?: () => void;
   adminPage?: boolean;
   refresh: () => void;
   bulkUnpublishDocuments: (selectedIds: number[]) => void;
   bulkResendDocuments: (selectedIds: number[]) => void;
   bulkPublishDocuments: (selectedIds: number[], data: BulkForm) => void;
   canBulkPublish: boolean;
   fundPage?: boolean;
   onSortClick: (name: string) => void;
   sortOrder: 'ASC' | 'DESC' | null;
   sortBy: string | undefined;
   selectedIds: number[];
   setSelectedIds: Dispatch<SetStateAction<number[]>>;
   onSendDocumentToUser?: (documentId: number) => void;
}

interface DocumentViewerProps {
   url: string;
   filename: string;
   date: string;
   documentType: DocTypeGet;
}

function DocumentTable({
   documents,
   setSelectedDocument,
   handleEdit,
   adminPage,
   refresh,
   bulkUnpublishDocuments,
   bulkResendDocuments,
   bulkPublishDocuments,
   canBulkPublish,
   fundPage,
   onSortClick,
   sortOrder,
   sortBy,
   selectedIds,
   setSelectedIds,
   onSendDocumentToUser,
}: Props) {
   const [errorMsg, setErrorMsg] = useState('');

   const [displayBulkPublishModal, setDisplayBulkPublishModal] =
      useState(false);

   const handleCheckBoxChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      const documentIds = documents.map(document => document.id);

      if (checked) {
         setSelectedIds([...selectedIds, ...documentIds]);
      } else {
         //remove documents on un-check
         const toRemove = new Set(documentIds);
         setSelectedIds(selectedIds.filter(id => !toRemove.has(id)));
      }
   };

   const handleCheckBoxChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      id: number,
   ) => {
      const { checked } = e.target;
      const documentIds = checked
         ? [...selectedIds, id]
         : selectedIds.filter(documentId => documentId !== id);
      setSelectedIds(documentIds);
   };

   const bulkDownloadDocuments = () => {
      api.download(`document/bulk-download?ids=${selectedIds.join(',')}`).catch(
         err => setErrorMsg(err.message),
      );
   };

   const handleBulkPublishDocuments = (data: BulkForm) => {
      setDisplayBulkPublishModal(false);
      bulkPublishDocuments(selectedIds, data);
   };

   return (
      <>
         <div className="bg-white overflow-hidden shadow rounded-lg">
            {errorMsg && <Banner type="error"> {errorMsg} </Banner>}
            <FormModal
               title="Bulk Publish"
               display={displayBulkPublishModal}
               onClose={() => setDisplayBulkPublishModal(false)}
            >
               <BulkPublishDocument onSubmit={handleBulkPublishDocuments} />
            </FormModal>

            {selectedIds.length ? (
               <div className="bg-gray-200 text-left py-2">
                  <button
                     onClick={bulkDownloadDocuments}
                     className="ml-2 inline-flex items-center px-3 py-2 border 
          border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  >
                     Download Selected Documents
                  </button>
                  {adminPage && (
                     <>
                        <button
                           onClick={() => bulkUnpublishDocuments(selectedIds)}
                           className="ml-2 inline-flex items-center px-3 py-2 border 
               border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                        >
                           Depublish Selected
                        </button>
                        <button
                           title="Can only bulk publish unpublished documents"
                           disabled={!canBulkPublish}
                           onClick={() => setDisplayBulkPublishModal(true)}
                           className={`ml-2 inline-flex items-center px-3 py-2 border 
                           border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 
                             focus:outline-none ${
                                canBulkPublish
                                   ? 'bg-white hover:bg-gray-50'
                                   : 'bg-gray-300 cursor-auto'
                             }`}
                        >
                           Publish Selected
                        </button>
                        <button
                           onClick={() => bulkResendDocuments(selectedIds)}
                           className="ml-2 inline-flex items-center px-3 py-2 border 
               border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                        >
                           Resend Selected
                        </button>
                     </>
                  )}
               </div>
            ) : null}

            <div>
               <div className="max-w-6xl">
                  <div className="flex flex-col">
                     <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                           <thead>
                              <tr>
                                 <th className="px-6 py-3 bg-gray-50">
                                    <div className="flex items-center h-5">
                                       <input
                                          onChange={handleCheckBoxChangeAll}
                                          type="checkbox"
                                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                       />
                                    </div>
                                 </th>
                                 <th
                                    onClick={() => onSortClick('fileName')}
                                    className="cursor-pointer px-2 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                 >
                                    Document Name
                                    {sortBy === 'fileName' && (
                                       <DocumentSortIcon order={sortOrder} />
                                    )}
                                 </th>
                                 <th
                                    onClick={() => onSortClick('documentDate')}
                                    className="cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                 >
                                    Date
                                    {sortBy === 'documentDate' && (
                                       <DocumentSortIcon order={sortOrder} />
                                    )}
                                 </th>

                                 <th
                                    onClick={() => onSortClick('publishedAt')}
                                    className="cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                 >
                                    Published
                                    {sortBy === 'publishedAt' && (
                                       <DocumentSortIcon order={sortOrder} />
                                    )}
                                 </th>

                                 <th
                                    onClick={() => onSortClick('fundName')}
                                    className="cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                 >
                                    Fund
                                    {sortBy === 'fundName' && (
                                       <DocumentSortIcon order={sortOrder} />
                                    )}
                                 </th>
                                 {fundPage && (
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                       Batch Id
                                    </th>
                                 )}

                                 <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                 </th>
                              </tr>
                           </thead>
                           <tbody className="bg-white divide-y divide-gray-200">
                              {documents.map(document => (
                                 <DocumentCard
                                    key={document.id}
                                    fundPage={fundPage}
                                    refresh={refresh}
                                    adminPage={adminPage}
                                    onSendDocumentToUser={onSendDocumentToUser}
                                    onChange={e =>
                                       handleCheckBoxChange(e, document.id)
                                    }
                                    checked={selectedIds.includes(document.id)}
                                    document={document}
                                    setSelectedDocument={setSelectedDocument}
                                    isTableView={true}
                                 />
                              ))}
                              {/* More items... */}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default DocumentTable;

import Navbar from './Navbar';
import NavItem from './NavItem';
import NavItems from './NavItems';
import PopoverMenu from './PopoverMenu';
import FixedBanner from '../FixedBanner';
import { UserContext, isFundManager } from '../../contexts/UserContext';
import { useContext } from 'react';
import { UserToken } from 'types/index';
import { getImpersonationId } from '../../lib/api';

function UserNavbar() {
   const { user } = useContext(UserContext) as { user: UserToken };
   const isImpersonation = Boolean(getImpersonationId());
   const bgColor = isImpersonation ? 'bg-yellow-200' : undefined;
   return (
      <>
         <Navbar bgColor={bgColor}>
            <NavItems>
               <NavItem href="/" className="hidden mx-4 sm:flex">
                  Documents
               </NavItem>

               {user?.hasForms && (
                  <NavItem href="/forms" className="hidden mx-4 sm:flex">
                     Forms
                  </NavItem>
               )}

               {user?.hasFundManagerDocuments && (
                  <NavItem href="/files" className="hidden mx-4 sm:flex">
                     Approvals
                  </NavItem>
               )}

               {isFundManager(user) && (
                  <NavItem href="/requests" className="hidden mx-4 sm:flex">
                     Requests
                  </NavItem>
               )}

               <NavItem href="/contact" className="hidden sm:flex">
                  Contact Us
               </NavItem>
            </NavItems>
            <div className="flex">
               <PopoverMenu />
            </div>
         </Navbar>
         {process.env.REACT_APP_BANNER_NOTIFICATION ? (
            <FixedBanner className="relative top-16">
               {process.env.REACT_APP_BANNER_NOTIFICATION}
            </FixedBanner>
         ) : null}
      </>
   );
}

export default UserNavbar;

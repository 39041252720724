export default function ({
   className,
   children,
}: React.PropsWithChildren<{ className?: string }>) {
   return (
      <div
         className={
            'text-center py-2 bg-yellow-100 text-gray-500 w-full ' + className
         }
      >
         {children}
      </div>
   );
}
